<template>
    <div class="review-page">
        <div class="review-content">
            <div class="review-patient review-column review-column-small">
                <h3 class="review-section-header">
                    {{ $store.getters.patientFirstname }} {{ $store.getters.patientLastName }}
                </h3>
                <router-link to="/demographic" class="review-edit">
                    edit
                </router-link>
                <div class="review-content-section review-content-section--ntm">
                    <p class="review-text">
                        Born on {{ $store.getters.patientDob }}
                    </p>
                    <p class="review-text">
                        {{ $store.getters.patientSex }}
                    </p>
                </div>
                <div class="review-content-section">
                    <p class="review-text">
                        {{ $store.getters.patientStreet }}.
                    </p>
                    <p class="review-text">
                        {{ $store.getters.patientCity }}, {{ $store.getters.patientState }} {{ $store.getters.patientZip }}
                    </p>
                </div>
                <div class="review-content-section">
                    <p class="review-text">
                        {{ phoneNumber }}
                    </p>
                    <p class="review-text">
                        {{ $store.getters.patientEmail }}
                    </p>
                </div>
            </div>
            <div class="review-insurance review-column review-column-large">
                <h3 class="review-section-header">
                    Insurance
                </h3>
                <div class="review-insurance-flex">
                    <div class="review-leftInsurance review-insurance-flexItem">
                        <insurance-card-error v-if="primaryInsuranceCardsMissing" :primary-insurance="true">
                            Error Message
                        </insurance-card-error>
                        <insurance-info v-else :primary-insurance="true" />
                    </div>
                    <div class="review-rightInsurance review-insurance-flexItem review-insurance-flexItem--lm">
                        <insurance-card-error v-if="secondaryInsuranceCardsMissing" :primary-insurance="false">
                            Error Message
                        </insurance-card-error>
                        <insurance-info v-else :primary-insurance="false" />
                    </div>
                </div> 
            </div>
            <div class="review-medical review-column review-column-small">
                <h3 class="review-section-header">
                    Medical
                </h3>
                <router-link to="/medical" class="review-edit">
                    edit
                </router-link>
                <div class="review-content-section">
                    <h4 class="review-bold-header">
                        Medication(s) that did not work for me
                    </h4>
                    <p v-for="(item, index) in $store.getters.previousMedications" 
                       :key="index"
                       class="review-text"
                    >
                        {{ item.name }}
                    </p>
                    <p v-if="$store.getters.previousMedications.length <= 0" class="review-text">
                        None
                    </p>
                </div>
                <div class="review-content-section">
                    <h4 class="review-bold-header">
                        Recent symptons
                    </h4>
                    <p class="review-text">
                        All questions answered
                    </p>
                </div>
                <div v-if="shouldShowFrequency" class="review-content-section">
                    <h4 class="review-bold-header">
                        Depressive episode frequency
                    </h4>
                    <p class="review-text">
                        {{ $store.getters.frequencyOfEpisodes }}
                    </p>
                </div>
                <div class="review-content-section">
                    <h4 class="review-bold-header">
                        Treated for anxiety
                    </h4>
                    <p class="review-text">
                        {{ $store.getters.hasAnxiety }}
                    </p>
                </div>
            </div>
        </div>
        <btn-row :disable-continue="primaryInsuranceCardsMissing || secondaryInsuranceCardsMissing" btn-string="Complete" @backClick="goToMedicalPage" @continueClick="submitPatientInfo" />
    </div>
</template>

<script>
import BtnRow from "../components/layout/BtnRow.vue";
import InsuranceInfo from "../components/ui/Medical/InsuranceInfo.vue";
import InsuranceCardError from "../components/layout/Review/InsuranceCardError.vue";
import PatientHttp from "../scripts/http/patient-http.js";
import Common from "../scripts/common.js";
import BasePage from "./BasePage.vue";

export default {
    name: "Review",
    components: {
        BtnRow,
        InsuranceInfo,
        InsuranceCardError
    },
    extends: BasePage,
    computed: {
        phoneNumber: function() {
            const phone = this.$store.getters.patientPhone;
            if(phone != null) {
                return this.$store.getters.patientPhone.replace("-", ".");
            }
            else {
                return "";
            }
        },
        primaryInsuranceCardsMissing() {
            return this.$store.getters.getArePrimaryCardsMissing && this.$store.getters.getPrimaryInsuranceCardsAdded;
        },
        secondaryInsuranceCardsMissing() {
            return this.$store.getters.getAreSecondaryCardsMissing && this.$store.getters.getSecondaryInsuranceCardsAdded;
        },
        shouldShowFrequency() {
            return Common.shouldShowDepressiveFrequency();
        }
    },
    methods: {
        goToMedicalPage() {
            this.$router.push("/medical");
        },
        submitPatientInfo() {
            this.$store.dispatch("setReviewStepComplete", true);

            var patient = this.$store.getters.patient;
            patient.insurances = this.getFormattedInsurances(patient);
            patient.previousMedications = this.$store.getters.previousMedicationsForPost;
            patient.PHQ9Responses = this.getFormattedPHQ9Responses();
            patient.hasAnxiety = this.$store.getters.hasAnxiety === "Yes";
            patient.depressionFrequency = this.$store.getters.frequencyOfEpisodes;

            // Add insurance card images to patient object to ensure data and documents send in one post
            patient.primaryCardFront = this.$store.getters.primaryFrontInsuranceCard;
            patient.primaryCardBack = this.$store.getters.primaryBackInsuranceCard;
            patient.secondaryCardFront = this.$store.getters.secondaryFrontInsuranceCard;
            patient.secondaryCardBack = this.$store.getters.secondaryBackInsuranceCard;

            this.$store.dispatch("setShowStartupLoader", true);
            PatientHttp.postPatientInfo(patient).then(()=> {
                this.$store.dispatch("setShowStartupLoader", false);
                this.$router.push("/complete");
            }).catch(() => {
                this.$store.dispatch("setShowStartupLoader", false);
                this.$router.push("/error");
            });
            
        },
        getFormattedPHQ9Responses() {
            var dictionary = {};
            this.$store.getters.phq9Questions.forEach(function(q){
                dictionary[q.questionId] = q.answer;
            });

            return dictionary;
        },
        getFormattedInsurances(patient) {
            var insurances = [];

            if(!this.$store.getters.primaryNoInsurance){
                var primaryInsurance = this.setPolicyHolderDemographics(this.$store.getters.insurance.primary, patient);
                var secondaryInsurance = this.setPolicyHolderDemographics(this.$store.getters.insurance.secondary, patient);
                
                primaryInsurance.isMedicare = primaryInsurance.isMedicare === "Yes";
                secondaryInsurance.isMedicare = secondaryInsurance.isMedicare === "Yes";

                if (secondaryInsurance.hasSecondaryInsurance === "Yes") {
                    insurances = [ primaryInsurance, secondaryInsurance ];
                }
                else {
                    insurances = [ primaryInsurance ];
                }
            }

            return insurances;
        },
        setPolicyHolderDemographics(insurance, patient){
            insurance.relationship = insurance.relationship.replace(" ", "");

            if (insurance.relationship === "Me") {
                insurance.firstName = patient.firstName;
                insurance.lastName = patient.lastName;
                insurance.dateOfBirth = patient.dateOfBirth;
                insurance.sex = patient.sex;
            }

            return insurance;
        }
    }
};
</script>

<style lang="scss" scoped>
.review-page {
    @include site-section();
    margin-top: 25px;
}

.review-content {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 75px;
}

.review-column {
    padding: 9px;
    padding-bottom: 50px;
    border: 1px solid #9596A1;
    border-radius: 5px;
}

.review-insurance-flex {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.review-insurance-flexItem {
    width: 50%;
    flex-grow: 1;
    box-sizing: border-box;
}

.review-insurance-flexItem--lm {
    margin-left: 50px;
}

.review-column-small {
    width: 25%;
    min-width: 250px;
    box-sizing: border-box;
}

.review-column-large {
    width: 50%;
    margin-left: 10px;
    margin-right: 10px;
}

.review-section-header {
    display: inline-block;
    font-weight: 700;
    font-size: $medical-section-header-font-size;
}

.review-edit {
    float: right;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

::v-deep .review-content-section {
    margin-top: 30px;
}

::v-deep .review-content-section--ntm {
    margin-top: 0;
}

::v-deep .review-text {
    font-size: $regular-copy-size;
}

::v-deep .review-bold-header {
    font-weight: 700;
    font-size: $regular-copy-size;
}

@include small-desktop() {
    .review-page {
        @include site-section-sm-desktop();
    }

    .review-insurance-flex {
        flex-direction: column;
    }

    .review-insurance-flexItem {
        width: 100%;
    }

    .review-insurance-flexItem--lm {
        margin-left: 0;
        margin-top: 50px;
    }

    .review-column-small,
    .review-column-large {
        width: 33%;
        min-width: 33%;
    }
}

@include tablet() {
    .review-content {
        flex-direction: column;
    }

    .review-column-small,
    .review-column-large {
        width: 100%;
        margin: 0;
    }

    .review-column-large {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}   
</style>
