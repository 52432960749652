import Common from "../../scripts/common.js";

export default {
    state: {
        primaryPolicyholderRelationship: "",
        primaryPolicyholderFirstName: "",
        primaryPolicyholderLastName: "",
        primaryPolicyholderDateOfBirth: "",
        primaryPolicyholderSex: "",
        primaryInsuranceCompanyName: "",
        primaryInsuranceIdNumber: "",
        primaryInsuranceGroupNumber: "",
        primaryInsurancePhoneNumber: "",
        primaryInsuranceMedicare: "",
        primaryNoInsurance: false,

        secondaryPolicyholderRelationship: "",
        secondaryInsuranceIncluded: "",
        secondaryPolicyholderFirstName: "",
        secondaryPolicyholderLastName: "",
        secondaryPolicyholderDateOfBirth: "",
        secondaryPolicyholderSex: "",
        secondaryInsuranceCompanyName: "",
        secondaryInsuranceIdNumber: "",
        secondaryInsuranceGroupNumber: "",
        secondaryInsurancePhoneNumber: "",
        secondaryInsuranceMedicare: "",

        primaryInsuranceCardsAdded: false,
        secondaryInsuranceCardsAdded: false
    },
    getters: {
        insurance: state => {
            return {
                primary: {
                    insuranceNumber: 1,
                    companyName: state.primaryInsuranceCompanyName,
                    idNumber: state.primaryInsuranceIdNumber,
                    groupNumber: state.primaryInsuranceGroupNumber,
                    phoneNumber: state.primaryInsurancePhoneNumber,
                    relationship: state.primaryPolicyholderRelationship,
                    firstName: state.primaryPolicyholderFirstName,
                    lastName: state.primaryPolicyholderLastName,
                    dateOfBirth: state.primaryPolicyholderDateOfBirth,
                    sex: state.primaryPolicyholderSex,
                    isMedicare: state.primaryInsuranceMedicare
                },
                secondary: {
                    hasSecondaryInsurance: state.secondaryInsuranceIncluded,
                    insuranceNumber: 2,
                    companyName: state.secondaryInsuranceCompanyName,
                    idNumber: state.secondaryInsuranceIdNumber,
                    groupNumber: state.secondaryInsuranceGroupNumber,
                    phoneNumber: state.secondaryInsurancePhoneNumber,
                    relationship: state.secondaryPolicyholderRelationship,
                    firstName: state.secondaryPolicyholderFirstName,
                    lastName: state.secondaryPolicyholderLastName,
                    dateOfBirth: state.secondaryPolicyholderDateOfBirth,
                    sex: state.secondaryPolicyholderSex,
                    isMedicare: state.secondaryInsuranceMedicare
                }
            };
        },
        primaryPolicyholderRelationship: state => {
            return state.primaryPolicyholderRelationship;
        },
        primaryPolicyholderFirstName: state => {
            return state.primaryPolicyholderFirstName;
        },
        primaryPolicyholderLastName: state => {
            return state.primaryPolicyholderLastName;
        },
        primaryPolicyholderDateOfBirth: state => {
            return state.primaryPolicyholderDateOfBirth;
        },
        primaryPolicyholderSex: state => {
            return state.primaryPolicyholderSex;
        },
        primaryInsuranceCompanyName: state => {
            return state.primaryInsuranceCompanyName;
        },
        primaryInsuranceIdNumber: state => {
            return state.primaryInsuranceIdNumber;
        },
        primaryInsuranceGroupNumber: state => {
            return state.primaryInsuranceGroupNumber;
        },
        primaryInsurancePhoneNumber: state => {
            return state.primaryInsurancePhoneNumber;
        },
        primaryInsuranceMedicare: state => {
            return state.primaryInsuranceMedicare;
        },
        primaryNoInsurance: state => {
            return state.primaryNoInsurance;
        },
        secondaryInsuranceIncluded: state => {
            return state.secondaryInsuranceIncluded;
        },
        secondaryPolicyholderRelationship: state => {
            return state.secondaryPolicyholderRelationship;
        },
        secondaryPolicyholderFirstName: state => {
            return state.secondaryPolicyholderFirstName;
        },
        secondaryPolicyholderLastName: state => {
            return state.secondaryPolicyholderLastName;
        },
        secondaryPolicyholderDateOfBirth: state => {
            return state.secondaryPolicyholderDateOfBirth;
        },
        secondaryPolicyholderSex: state => {
            return state.secondaryPolicyholderSex;
        },
        secondaryInsuranceCompanyName: state => {
            return state.secondaryInsuranceCompanyName;
        },
        secondaryInsuranceIdNumber: state => {
            return state.secondaryInsuranceIdNumber;
        },
        secondaryInsuranceGroupNumber: state => {
            return state.secondaryInsuranceGroupNumber;
        },
        secondaryInsurancePhoneNumber: state => {
            return state.secondaryInsurancePhoneNumber;
        },
        secondaryInsuranceMedicare: state => {
            return state.secondaryInsuranceMedicare;
        },
        getPrimaryInsuranceCardsAdded:state => {
            return state.primaryInsuranceCardsAdded;
        },
        getSecondaryInsuranceCardsAdded:state => {
            return state.secondaryInsuranceCardsAdded;
        }
    },
    mutations: {
        setPrimaryInsurancePolicyholderRelationship(state, value) {
            state.primaryPolicyholderRelationship = value;
        },
        setPrimaryInsuranceFirstName(state, value) {
            state.primaryPolicyholderFirstName = value;
        },
        setPrimaryInsuranceLastName(state, value) {
            state.primaryPolicyholderLastName = value;
        },
        setPrimaryInsurancePolicyholderDateOfBirth(state, value) {
            state.primaryPolicyholderDateOfBirth = value;
        },
        setPrimaryInsuranceSex(state, value) {
            state.primaryPolicyholderSex = value;
        },
        setPrimaryInsuranceCompanyName(state, value) {
            state.primaryInsuranceCompanyName = value;
        },
        setPrimaryInsuranceIdNumber(state, value) {
            state.primaryInsuranceIdNumber = value;
        },
        setPrimaryInsuranceGroupNumber(state, value) {
            state.primaryInsuranceGroupNumber = value;
        },
        setPrimaryInsurancePhoneNumber(state, value) {
            state.primaryInsurancePhoneNumber = value;
        },
        setPrimaryInsuranceMedicare(state, value) {
            state.primaryInsuranceMedicare = value;
        },
        setPrimaryNoInsurance(state, value) {
            state.primaryNoInsurance = value;
        },
        clearPrimaryInsurance(state) {
            state.primaryPolicyholderRelationship = "";
            state.primaryPolicyholderFirstName = "";
            state.primaryPolicyholderLastName = "";
            state.primaryPolicyholderDateOfBirth = "";
            state.primaryPolicyholderSex = "";
            state.primaryInsuranceCompanyName = "";
            state.primaryInsuranceIdNumber = "";
            state.primaryInsuranceGroupNumber = "";
            state.primaryInsurancePhoneNumber = "";
            state.primaryInsuranceMedicare = "";
        },
        setSecondaryInsurancePolicyholderRelationship(state,value) {
            state.secondaryPolicyholderRelationship = value;
        },
        setSecondaryInsuranceIncluded(state, value) {
            state.secondaryInsuranceIncluded = value;
        },
        setSecondaryInsuranceFirstName(state, value) {
            state.secondaryPolicyholderFirstName = value;
        },
        setSecondaryInsuranceLastName(state, value) {
            state.secondaryPolicyholderLastName = value;
        },
        setSecondaryInsurancePolicyholderDateOfBirth(state, value) {
            state.secondaryPolicyholderDateOfBirth = value;
        },
        setSecondaryInsuranceSex(state, value) {
            state.secondaryPolicyholderSex = value;
        },
        setSecondaryInsuranceCompanyName(state, value) {
            state.secondaryInsuranceCompanyName = value;
        },
        setSecondaryInsuranceIdNumber(state, value) {
            state.secondaryInsuranceIdNumber = value;
        },
        setSecondaryInsuranceGroupNumber(state, value) {
            state.secondaryInsuranceGroupNumber = value;
        },
        setSecondaryInsurancePhoneNumber(state, value) {
            state.secondaryInsurancePhoneNumber = value;
        },
        setSecondaryInsuranceMedicare(state, value) {
            state.secondaryInsuranceMedicare = value;
        },
        clearSecondaryInsuranceInfo(state) {
            state.secondaryPolicyholderRelationship = "";
            state.secondaryPolicyholderFirstName = "";
            state.secondaryPolicyholderLastName = "";
            state.secondaryPolicyholderDateOfBirth = "";
            state.secondaryPolicyholderSex = "";
            state.secondaryInsuranceCompanyName = "";
            state.secondaryInsuranceIdNumber = "";
            state.secondaryInsuranceGroupNumber = "";
            state.secondaryInsurancePhoneNumber = "";
            state.secondaryInsuranceMedicare = "";
        },
        setPrimaryInsuranceCardsAdded(state, value) {
            state.primaryInsuranceCardsAdded = value;
        },
        setSecondaryInsuranceCardsAdded(state, value) {
            state.secondaryInsuranceCardsAdded = value;
        }
    },
    actions: {
        setPrimaryInsurancePolicyholderRelationship(context, value) {
            context.commit("setPrimaryInsurancePolicyholderRelationship", value);
        },
        setPrimaryInsuranceFirstName(context, value) {
            context.commit("setPrimaryInsuranceFirstName", value);
        },
        setPrimaryInsuranceLastName(context, value) {
            context.commit("setPrimaryInsuranceLastName", value);
        },
        setPrimaryInsurancePolicyholderDateOfBirth(context, value) {
            let convertedDob = Common.ConvertTwoDigitYearToFour(value);
            context.commit("setPrimaryInsurancePolicyholderDateOfBirth", convertedDob);
        },
        setPrimaryInsuranceSex(context, value) {
            context.commit("setPrimaryInsuranceSex", value);
        },
        setPrimaryInsuranceCompanyName(context, value) {
            context.commit("setPrimaryInsuranceCompanyName", value);
        },
        setPrimaryInsuranceIdNumber(context, value) {
            context.commit("setPrimaryInsuranceIdNumber", value);
        },
        setPrimaryInsuranceGroupNumber(context, value) {
            context.commit("setPrimaryInsuranceGroupNumber", value);
        },
        setPrimaryInsurancePhoneNumber(context, value) {
            context.commit("setPrimaryInsurancePhoneNumber", value);
        },
        setPrimaryInsuranceMedicare(context, value) {
            context.commit("setPrimaryInsuranceMedicare", value);
        },
        setPrimaryNoInsurance(context, value) {
            context.commit("setPrimaryNoInsurance", value);
        },
        clearPrimaryInsurance(context) {
            context.commit("clearPrimaryInsurance");
        },
        setSecondaryInsurancePolicyholderRelationship(context, value) {
            context.commit("setSecondaryInsurancePolicyholderRelationship", value);
        },
        setSecondaryInsuranceIncluded(context, value) {
            context.commit("setSecondaryInsuranceIncluded", value);
        },
        setSecondaryInsuranceFirstName(context, value) {
            context.commit("setSecondaryInsuranceFirstName", value);
        },
        setSecondaryInsuranceLastName(context, value) {
            context.commit("setSecondaryInsuranceLastName", value);
        },
        setSecondaryInsurancePolicyholderDateOfBirth(context, value) {
            let convertedDob = Common.ConvertTwoDigitYearToFour(value);
            context.commit("setSecondaryInsurancePolicyholderDateOfBirth", convertedDob);
        },
        setSecondaryInsuranceSex(context, value) {
            context.commit("setSecondaryInsuranceSex", value);
        },
        setSecondaryInsuranceCompanyName(context, value) {
            context.commit("setSecondaryInsuranceCompanyName", value);
        },
        setSecondaryInsuranceIdNumber(context, value) {
            context.commit("setSecondaryInsuranceIdNumber", value);
        },
        setSecondaryInsuranceGroupNumber(context, value) {
            context.commit("setSecondaryInsuranceGroupNumber", value);
        },
        setSecondaryInsurancePhoneNumber(context, value) {
            context.commit("setSecondaryInsurancePhoneNumber", value);
        },
        setSecondaryInsuranceMedicare(context, value) {
            context.commit("setSecondaryInsuranceMedicare", value);
        },
        clearSecondaryInsuranceInfo(context) {
            context.commit("clearSecondaryInsuranceInfo");
        },
        setPrimaryInsuranceCardsAdded(context, value) {
            context.commit("setPrimaryInsuranceCardsAdded", value);
        },
        setSecondaryInsuranceCardsAdded(context, value) {
            context.commit("setSecondaryInsuranceCardsAdded", value);
        }
    }
};
